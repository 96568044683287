<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="고객사 정보"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>고객사명</th>
                                <td>{{ view.info.kname }}</td>
                            </tr>
                            <tr>
                                <th>고객사 영문명</th>
                                <td>{{ view.info.ename }}</td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td>{{ view.info.zipcode?'('+view.info.zipcode+')':'' }} {{ view.info.addr }} {{ view.info.addr_sub }}
                                <br> <span class="txt-red"> *고객사 정보에 있는 주소로 명함이 등록됩니다. 항상 최신으로 업데이트 해주세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th>대표자명</th>
                                <td>{{ view.info.ceo_name }}</td>
                            </tr>
                            <tr>
                                <th>사업자번호</th>
                                <td>{{ view.info.bizno }}</td>
                            </tr>
                            <tr>
                                <th>고객사 단계</th>
                                <td>{{ view.info.step }} 단계</td>
                            </tr>
                            <tr>
                                <th>고객사 유형</th>
                                <td>{{ view.info.company_type }}</td>
                            </tr>
                            <tr>
                                <th>고객사 산업군</th>
                                <td>{{ view.info.industry_type }}</td>
                            </tr>
                            <tr>
                                <th>고객사 특이사항</th>
                                <td class="preline">{{ view.info.special_memo }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="view.goList" class="btn-default float-left mt-30">목록</button>
                    <button @click="view.showModify" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>

                  <table width="100%">
                    <tr>
                      <th width="100%"> <h3 class="block-title mt-40 mb-20">메인 담당자
                        <br><span class="txt-red"> *메인 담당자만 노출되며 메인이 아닐 경우 명함에서 확인가능합니다. 명함에서 메인으로 Y 체크 시, 여기에 노출됩니다</span>
                      </h3>
                      </th>
                      <th style="text-align:right"> <router-link :to="{ name:'customerManagement-businessCardAdd' }"><button class="btn-default mb-20">명함등록</button></router-link></th>
                    </tr>
                  </table>
                    <table class="table-col">
                        <colgroup>
                            <col width="160">
                            <col width="100">
                            <col width="120">
                            <col width="120">
                            <col width="50">
                            <col width="100">
                            <col width="*">
                            <col width="80">
                            <col width="200">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>담당자명</th>
                                <th>직급</th>
                                <th>Tel</th>
                                <th>Mobile</th>
                                <th>메인</th>
                                <th>컨퍼런스<br>참석이력</th>
                                <th>이메일</th>
                                <th>재직 여부</th>
                                <th>매니저</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.mgr_list" :key="i">
                                <td><router-link :to="{ name:'customerManagement-businessCardView-idx', params:{ idx:irow.idx } }"><span class="btn-view">{{ irow.kname }}</span></router-link></td>
                                <td>{{ irow.position }}</td>
                                <td>{{ irow.tel }}</td>
                                <td>{{ irow.mobile }}</td>
                                <td>Y</td>
                                <td>{{ irow.is_conference=='Y'?'Y':'' }}</td>
                                <td>{{ irow.email }}</td>
                                <td>{{ irow.is_work=='Y' ? '재직중' : '퇴사' }}</td>
                                <td>{{ irow.hq_ename }}({{ irow.hq_kname }})</td>
                            </tr>
                            <tr v-if="view.mgr_total==0">
                                <td colspan="9">담당자가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

              <!-- 고객사일지 -->
              <div class="mt-100">
                <table width="100%">
                  <tr>
                    <th width="86%">
                      <h3 class="block-title mb-20">외근목적별 정렬보기
                        <select v-model="view.rtype" @change="view.doSearch2()">
                          <option value="전체">전체</option>
                          <option :value="irow.code" v-for="(irow, i) in view.rtype_list" :key="i">{{irow.name}}</option>
                        </select>
                        <br>
                        <span class="txt-red">*마케팅 미팅, 오픈 등 외근목적별 따로 정렬 보기가 가능합니다</span>
                      </h3>
                    </th>
                    <th>
                      <router-link  :to="{ name: 'customerManagement-scheduleOutsideWorkAdd-idx', params:{ idx:view.idx } }"><button class="btn-default ml-20">고객사일지 작성</button></router-link>
                    </th>
                  </tr>
                </table>

                <table class="table-col">
                  <thead>
                    <tr v-if="view.total2==0">
                      <th>No</th>
                      <th>방문일시</th>
                      <th>업체담당자</th>
                      <th>수업지속성</th>
                      <th>관계지속성</th>
                      <th>외근목적</th>
                      <th>클래스</th>
                      <th>HQ매니저</th>
                      <th>작성일</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr  class="hover-none" v-for="(irow, i) in view.list2" :key="i">
                      <table class="table-col" >
                        <colgroup>
                          <col width="60">
                          <col width="100">
                          <col width="90">
                          <col width="90">
                          <col width="90">
                          <col width="90">
                          <col width="*">
                          <col width="180">
                          <col width="100">
                        </colgroup>
                        <thead v-if="i==0">
                        <tr>
                          <th>No</th>
                          <th>방문일시</th>
                          <th>업체담당자</th>
                          <th>수업지속성</th>
                          <th>관계지속성</th>
                          <th>외근목적</th>
                          <th>클래스</th>
                          <th>HQ매니저</th>
                          <th>작성일</th>
                        </tr>
                        </thead>
                        <tr  style="cursor:pointer" @click="irow.isShow =='N' ? irow.isShow= 'Y':irow.isShow='N' ">
                          <td>{{ irow.num }}</td>
                          <td><router-link :to="{ name:'customerManagement-outsideWorkView-idx', params:{ idx:irow.idx } }"><span class="btn-view">{{ irow.vdate }}</span></router-link></td>
                          <td>{{ irow.kname }}</td>
                          <td>
                            <template v-for="(jrow, j) in view.cp_list" :key="j">
                              <img v-if="irow.class_persistence==jrow.code" :src="require('@/assets/img/db_relation_0' + (j+1) + '.png')" :alt="jrow.name">
                              <!--<span v-if="j == 0">-</span>-->
                            </template>
                          </td>
                          <td>
                            <template v-for="(jrow, j) in view.rp_list" :key="j">
                              <img v-if="irow.relation_persistence==jrow.code" :src="require('@/assets/img/db_durability_0' + (j+1) + '.png')" :alt="jrow.name">
                              <!--<span v-if="j == 0">-</span>-->
                            </template>
                          </td>
                          <td>{{ irow.rtype }}</td>
                          <td>
                            <a class="btn-view" :href="'https://hq.carrotenglish.net/auth/common/directClassLink?uid=' + view.email + '&classId=' + irow.out_prc_idx_class" target="new"
                            >{{ irow.out_prc_class }} </a>
                          </td>
                          <td>{{ irow.staff_ename }}({{ irow.staff_kname }})</td>
                          <td>{{ irow.wdate }}</td>
                        </tr>
                        <tr  v-if="irow.isShow=='Y' && irow.contents!=''">
                          <td colspan="9" style="background-color: #EAEAEA;"  >
                            <div class=" txt-s1 cal-item txt-left "> <span class="preline" v-html="irow.contents"></span>
                            </div>
                          </td>
                        </tr>
                      </table>
                  </tr>
                  <tr v-if="view.total2==0">
                    <td colspan="9">등록된 일지가 없습니다.</td>
                  </tr>
                  </tbody>
                </table>

                <CarrotPaging :total="view.total2" :list_per_page="view.rows2" v-model="view.page2" @change="view.doSearch2"></CarrotPaging>

                <div class="clear"></div>
                <h3 class="block-title mt-40 mb-20">변경이력</h3>

                <table class="table-col">
                  <colgroup>
                    <col width="*">
                    <col width="180">
                    <col width="180">
                  </colgroup>
                  <thead>
                  <tr>
                    <th>변경사항</th>
                    <th>변경일</th>
                    <th>변경 매니저</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(irow, i) in view.list" :key="i">
                    <td>{{ irow.memo }}</td>
                    <td>{{ irow.createdate }}</td>
                    <td>{{ irow.ename }}({{ irow.kname }})</td>
                  </tr>
                  <tr v-if="view.total==0">
                    <td colspan="3">변경 이력이 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
                <CarrotPaging :total="view.total" :list_per_page="view.rows" v-model="view.page" @change="view.doSearch"></CarrotPaging>
              </div>

            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import {useStore} from "vuex";


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const store = useStore();
        const view = reactive({
            idx : 0,
            info : {},

            mgr_list  : [],
            mgr_total : 0,

            page : 1, 
            rows : 10,
            list : {}, total : 0,
            rtype:"전체",
            is_main:'Y',
            info2 : {},
            cp_list : [],
            rp_list : [],
            rt_list : [],
            rtype_list:[],
            page2 : 1,
            rows2 : 10,
            list2 : {}, total2 : 0,
            email:store.state.email,


            goList : () => {
                router.push({
                    name : "customerManagement-customerDBList"
                });
            },

            showModify : () => {
                router.push({
                    name : "customerManagement-customerDBEdit-idx",
                    params : {
                        idx : view.idx
                    }
                });
            },

            doSearchMgr : () => {
                let params = {
                    idx  : view.idx,
                    is_main: view.is_main
                };

                axios.get("/rest/customermgr/getCustomerManager", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.mgr_total = res.data.total;
                        view.mgr_list  = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                if( view.idx == 0 ) return;

                let params = {
                    page : view.page,
                    rows : view.rows,
                    idx  : view.idx
                };

                axios.get("/rest/customermgr/getCustomerHistory", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.total = res.data.total;
                        view.list  = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearchInfo : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/customermgr/getCustomerDBInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;
                        view.doSearch();
                        view.doSearchMgr();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            doSearch2 : () => {
              if( view.idx == 0 ) return;

              let params = {
                page : view.page2,
                rows : view.rows2,
                idx  : view.idx,
                rtype : view.rtype
              };

              axios.get("/rest/customermgr/getCustomerSchedules", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  view.total2 = res.data.total;
                  view.list2  = res.data.list;


                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },
            doSearchCP : () => {
              axios.get("/rest/customermgr/getCPtypeList", { params:{} }).then((res) => {
                if( res.data.err == 0 ){
                  view.cp_list = res.data.list;
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              })
            },

            doSearchRP : () => {
              axios.get("/rest/customermgr/getRPtypeList", { params:{} }).then((res) => {
                if( res.data.err == 0 ){
                  view.rp_list = res.data.list;
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              })
            },
          doSearchRtype : () => {
            axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
              if( res.data.err == 0 ){
                view.rtype_list = res.data.list;
              } else {
                if(res.data.err_msg) toast.error(res.data.err_msg);
              }
            })
          },
            doSearchInfo2 : () => {
              let params = {
                idx : view.idx
              };

              axios.get("/rest/customermgr/getCustomerDBInfo", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  view.info2 = res.data;

                  view.doSearch2();
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },

        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }
            view.doSearchInfo();
            view.doSearchInfo2();
            view.doSearchCP();
            view.doSearchRP();
            view.doSearchRtype();
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>